import React from 'react';
import '../css/location.scss'
// import { Link } from 'react-router-dom';

const Location = () => {

  return (
    <div id="location">
      <div id="title">
        <h1>Location  de gradins</h1>
      </div>
      <div id="content">
        <p>Créacirque offre la possibilité de louer des gradins en bois.<br />
          Les planches sont en bois clair recouvert d'un tissu rouge.
          La structure est métallique et modulable.<br />
          Vous avez la possibilité de louer des gradins pour 100, 200 ou 300 places.</p>
        <div className='flex flex-wrap'>
          {/* <img className="v" alt='gradin 11' src='/images/gradins/gradins12.jpeg' /> */}
          <img className="h" alt='gradin 3' src='/images/gradins/gradins3.jpeg' />
          <img className="h" alt='gradin 4' src='/images/gradins/gradins4.jpeg' />
          <img className="h" alt='gradin 5' src='/images/gradins/gradins5.jpeg' />
          <img className="h" alt='gradin 2' src='/images/gradins/gradins2.jpeg' />
          <img className="h" alt='gradin 1' src='/images/gradins/gradins1.jpeg' />
          <img className="h" alt='gradin 6' src='/images/gradins/gradins6.jpg' />
          <img className="h" alt='gradin 7' src='/images/gradins/gradins7.jpeg' />
          <img className="h" alt='gradin 8' src='/images/gradins/gradins8.jpeg' />
          {/* <img className="h" alt='gradin 9' src='/images/gradins/gradins9.jpeg' /> */}
          {/* <img className="h" alt='gradin 10' src='/images/gradins/gradins10.jpeg' /> */}
          <img className="h-large" alt='gradin 11' src='/images/gradins/gradins11.jpeg' />
        </div>
        <p>
          Pour plus d'informations, merci de nous contacter par e-mail à creacirque@gmail.com ou par téléphone au +32(0) 474/05.21.96.
        </p>
      </div>
    </div>
  )
};

export default Location;
