
import React, { useEffect, useState } from 'react'; // , useContext
import CourseList from '../../components/CourseList';
import axiosInstance from "../../helpers/Axios";
import IContact from '../../interfaces/IContact';
// import { Link } from 'react-router-dom';
// import DisplayHorairesForm from '../../components/DisplayHorairesForm';
// import axiosInstance from "../../helpers/Axios";


const defaultList: ICourseObj[] = [];

interface IDay {
    name: string;
}

interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    nbMaxRegistrations: number;
    startTime: string;
    endTime: string;
}

interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
}

const Horaires = () => {


    const [list, setList] = useState(defaultList);
    const [visible, setVisible] = useState(true);
    const loading = list === defaultList && visible;

    useEffect(() => {
        if (loading)
            axiosInstance.get("/course")
                .then(response => {
                    //console.log('response', response);

                    setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
                    // setUserProfile(response.data);
                    // setLocation(response.data.location);
                })
                .catch((err) => {
                    const error = err.response === undefined ?
                        'Server in not reachable'
                        : err.response.data;
                    console.log('error', error);
                    setVisible(false);
                });
    }
    );

    return (
        <div id="horaires">
            <div id="title">
                <h1>Les horaires</h1>
            </div>
            <div id="content">


                <div id="aaaaa" className="column-content">
                    <h1 className="right"><span className="color-black small"><strong>Les cours de septembre 2024 à juin 2025</strong></span></h1>

                    <p>
                        <span className="small">
                            <strong>
                                <a href="/images/courses/horaires.jpg" rel="iframe" title="image horaires" className="fancybox" data-fancybox-type="image">
                                    <img alt="garçon en porté" className="img-center margin-leftright-auto block" height="172" src="/images/courses/horaires.jpg?fx=r_200_200" width="218" />
                                </a>
                            </strong>
                        </span>
                    </p>
                    <h3>Les nouveaux horaires de la saison 2024-2025</h3>
                    <p className="align-center"><span className="small"><strong><span className="big">&nbsp;<span className="color-f1c40f"></span></span></strong></span></p>

                    <p className="align-center"><span className="font-size: 16px;"><strong>Plusieurs cours sont déclinés en différents niveaux: niveau 1= débutant, niveau 2= avancé </strong></span></p>

                    <p className="align-center"><span className="font-size: 16px;"><strong>Les cours reprennent la semaine du 16 septembre 2024</strong></span></p>

                    <p className="align-center">&nbsp;</p>

                    <h1><span className="small">&nbsp;</span><span className="color-black"><span className="small"><strong>&nbsp;&nbsp;</strong><strong>&nbsp;&nbsp;&nbsp;</strong></span></span></h1>
                    {/* align="left" border="0" */}


                    {
                        loading ?
                            <p>Chargement en cours...</p>
                            :
                            visible ?
                                <div className="flex flex-wrap flex-space-between">
                                    {/* <RegistrationForm stages={undefined} courses={list} title="Choisir votre cours" /> */}
                                    <CourseList stages={undefined} courses={list} title="Liste des cours" />
                                </div>
                                :
                                <div>
                                    <p>Le système d'inscription est actuellement en panne.</p>
                                </div>
                    }
                    {/*
                    <table className="height: 0px;">
                        <tbody>
                            <tr>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="font-size:16px;"><span className="color-white"><strong>Lundi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></span></span></h1>

                                    <p>17h15-19h15: trampoline</p>

                                    <p>&nbsp;</p>

                                    <p>&nbsp;</p>

                                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span></p>

                                    <p><span className="color: rgb(0, 0, 0); small"><em>&nbsp;</em></span></p>
                                </td>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="color:#ffffff;"><span className="font-size:16px;"><strong>Mardi</strong></span></span></h1>

                                    <p><span className="small"></span><span className="small"> 17h-18h30: aérien enfants</span></p>

                                    <p><span className="small">18h30-20h00: aérien ados</span></p>

                                    <p><span className="small">20h00-21h15: aérien adultes &nbsp;&nbsp;</span><span className="color: rgb(0, 0, 0); small">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>

                                    <p><br />
                                        &nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="font-size:16px;"><span className="color:#ffffff;"><strong>Mercredi</strong></span></span></h1>

                                    <p><span className="small">14h-15h: pluridisciplinaire 1&nbsp; 6-8 ans<br />
                                        15h-16h30: pluridisciplinaire 2&nbsp; 8-10 ans</span><br />
                                        <span className="small">16h30-18h:pluridisciplinaire 3&nbsp; 10 ans et plus</span></p>

                                    <p>18h15-19h45: préparation physique</p>

                                    <p><span className="color: rgb(0, 0, 0); small"></span></p>

                                    <p><span className="color: rgb(0, 0, 0); small"></span></p>

                                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span></p>
                                </td>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="font-size:16px;"><span className="color:#ffffff;"><strong>Jeudi</strong></span></span></h1>

                                    <p><span className="small">17h15-18h45: acrobatie débutant</span></p>

                                    <p><span className="small">18h45-20h15: acrobatie avancé</span><br />
                                        &nbsp;</p>

                                    <p>&nbsp;</p>

                                    <p><br />
                                        &nbsp;</p>

                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="font-size:16px;"><span className="color:#ffffff;"><strong>Vendredi</strong></span></span></h1>

                                    <p><span className="small">17h30-19h: monocycle/jonglerie</span></p>

                                    <p><span className="small">19h-20h:entraînement libre&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>

                                    <p><span className="small">&nbsp;</span></p>
                                </td>
                                <td lang="" scope="" valign="top">
                                    <h1><span className="font-size:16px;"><span className="color:#ffffff;"><strong>Samedi</strong>&nbsp;&nbsp;&nbsp;</span></span></h1>

                                    <p>&nbsp;</p>

                                    <p>11h-12h30: pluridisciplinaire 6-12 ans</p>

                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span><span className="font-size:20px;"><span className="text-decoration: underline;">Début des cours le 16 septembre 2024</span></span></p>

                                    <p><span className="small">Il n'y a pas cours pendant les congés scolaires et les jours fériés.</span></p>
                                </td>
                                <td>
                                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span></p>

                                    <p>&nbsp;</p>

                                    <p>&nbsp;</p>

                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p>&nbsp;</p>

                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span></p>

                    <p>&nbsp;</p>

                    <p><span className="color: rgb(0, 0, 0); small">&nbsp;</span></p>

                    <h3><span className="color: rgb(0, 0, 0); small">&nbsp;</span></h3>

                    <h1><span className="color: rgb(0, 0, 0); small">&nbsp;</span></h1>

                    <h1><span className="color: rgb(0, 0, 0); small">&nbsp;</span></h1>

                    <h1><span className="color: rgb(0, 0, 0); small">&nbsp;</span></h1>

                    <h1><span className="color: rgb(0, 0, 0); small">&nbsp;</span></h1>
*/}
                </div>

                {/* {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayHorairesForm stages={undefined} courses={list} title="Choisir votre cours"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            } */}
            </div>
        </div>
    )
};

export default Horaires;
