
import React, { useEffect, useState } from 'react'; // , useContext
// import { Link } from 'react-router-dom';
// import DisplayInscriptionForm from '../../components/DisplayInscriptionForm';
import RegistrationForm from '../../components/RegistrationForm';
import CourseList from '../../components/CourseList';
import axiosInstance from "../../helpers/Axios";
import IContact from '../../interfaces/IContact';

const defaultList: ICourseObj[] = [];
// const defaultList = [
//   {shortName:"raté", name:"Tout va péter", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"", name:"Choisir votre cours", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"trampo", name:"Trampoline", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},{shortName:"circuit", name:"Circuit", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"pluri1", name:"Pluri 1", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},{shortName:"pluri2", name:"Pluri 2", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},{shortName:"pluri3", name:"Pluri 3", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"prepaphysique", name:"Prépa physique", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"acro1", name:"Acro 1", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"acro2", name:"Acro 2", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'},
// {shortName:"other", name:"Autre cours", day:{name:'lundi'}, startTime:'16:00:00', endTime:'18:00:00'}];

interface IDay {
    name: string;
}

interface ICourse {
    shortName: string;
    name: string;
    day: IDay;
    isVisible: boolean;
    nbMaxRegistrations: number;
    startTime: string;
    endTime: string;
}

interface ICourseObj {
    course: ICourse;
    subscribes: IContact[];
}
const Inscription = () => {


    const [list, setList] = useState(defaultList);
    const [visible, setVisible] = useState(true);
    const loading = list === defaultList && visible;

    useEffect(() => {
        if (loading)
            axiosInstance.get("/course")
                .then(response => {
                    //console.log('response', response);

                    setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
                    // setUserProfile(response.data);
                    // setLocation(response.data.location);
                })
                .catch((err) => {
                    const error = err.response === undefined ?
                        'Server in not reachable'
                        : err.response.data;
                    console.log('error', error);
                    setVisible(false);
                });
    }
    );

    return (
        <div id="Inscription">
            <div id="title">
                <h1>Inscription aux cours</h1>
            </div>
            <div id="content">
                {
                    loading ?
                        <p>Chargement en cours...</p>
                        :
                        visible ?
                            <div className="flex flex-wrap flex-space-between">
                                <RegistrationForm stages={undefined} courses={list} title="Choisir votre cours" />
                                <CourseList stages={undefined} courses={list} title="Liste des cours" />
                            </div>
                            :
                            <div>
                                <p>Le système d'inscription est actuellement en panne.</p>
                            </div>
                }

                {/* {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayInscriptionForm stages={undefined} courses={list} title="Choisir votre cours"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            } */}
            </div>
        </div>
    )
};

export default Inscription;
