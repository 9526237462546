
import React from 'react';
// import { Link } from 'react-router-dom';
// import DisplayDescriptionForm from '../../components/DisplayDescriptionForm';
// import axiosInstance from "../../helpers/Axios";

// const defaultList: ICourseObj[] = [];

// interface IDay {
//   name: string;
// }

// interface ICourse {
//   shortName: string;
//   name: string;
//   day: IDay;
//   isVisible: boolean;
//   startTime: string;
//   endTime: string;
// }


// interface IDescription {
//   DescriptionId: number,
//   firstName: string,
//   lastName: string
// }

// interface ICourseObj {
//   course: ICourse;
//   subscribes: IDescription[];
// }


const Description = () => {

    // const [list, setList] = useState(defaultList);
    // const [visible, setVisible] = useState(true);
    // const loading = list === defaultList && visible;

    // useEffect(() => {
    //     if (loading)
    //         axiosInstance.get("/course")
    //             .then(response => {
    //                 //console.log('response', response);

    //                 setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
    //                 // console.log("réponse",response.data);
    //                 // setUserProfile(response.data);
    //                 // setLocation(response.data.location);
    //             })
    //             .catch((err) => {
    //                 const error = err.response === undefined ?
    //                     'Server in not reachable'
    //                     : err.response.data;
    //                 console.log('error', error);
    //                 setVisible(false);
    //             });
    // }
    // );

    // console.log("list", list);

    return (
        <div id="Description">
            <div id="title">
                <h1>Description des cours</h1>
            </div>
            <div id="content">


                <div id="aaa" className="column-content">
                    <p>
                        <a href="/images/courses/description.jpg" rel="iframe" title="image description" className="fancybox" data-fancybox-type="image"><img alt="garçon sur ballon" className="img-center" height="149" src="/images/courses/description.jpg" width="200" /></a>&nbsp;</p>
                    <p>
                        <strong>Cette volonté de recherche et de créativité est exploitée au travers de différents cours de techniques du cirque&nbsp;:</strong></p>

                    <ul>
                        <li>L'acrobatie&nbsp;: C'est l'art du mouvement, la gestion du risque.&nbsp; Cela nécessite le développement de la souplesse, de la tonicité, de repères spatiaux et de réflexes. L'acrobatie permet également une conscience plus profonde de son corps.&nbsp; L'acro-porté, les pyramides, les exercices au sol sont testés.</li>
                    </ul>

                    <ul>
                        <li>Le monocycle&nbsp;: Il s'agit d'exercices d'équilibre et de dextérité.&nbsp; Un petit noyau d'une dizaine de monocyclistes est présent au cours de mono-avancé.&nbsp; Des exercices chorégraphiques de groupe sont essayés ainsi que du mono-trial, du mono-basket.&nbsp; Ce cours démontre explicitement la richesse créative que peut apporter un groupe.</li>
                    </ul>

                    <ul>
                        <li>Pluridisciplinaire&nbsp;:Il s'agit d'aborder toutes les disciplines circassiennes afin de toucher aux différentes facettes qu'offre le cirque (l'équilibre, la jonglerie, l'aérien, l'acrobatie, le jeu d'acteur).</li>
                    </ul>

                    <ul>
                        <li>Le trapèze&nbsp;: Il s'agit de trapèze fixe, corde et tissu et cerceau.&nbsp; Il faut savoir se repérer dans l'espace, gérer sa peur et le risque.</li>
                    </ul>

                    <ul>
                        <li>La circomotricité&nbsp;: Il s'agit d'une exploration des techniques de cirque pour les enfants à partir de 5 ans.&nbsp; Cet apprentissage mélange cirque et psychomotricité via une approche ludique et créative.</li>
                    </ul>

                    <ul>
                        <li>La jonglerie&nbsp;: C'est l'art de la manipulation d'objets, un travail nécessitant justesse et précision.&nbsp; Après&nbsp; la première phase d'apprentissage de la technique, l'ambition est de mettre la personne en situation de création.</li>
                    </ul>

                    <ul>
                        <li>Le grand trampoline: Il s'agit d'exploiter les figures de base du trampoline (plat dos, vrille...).</li>
                    </ul>

                    <ul>
                        <li>La circomotricité: C'est un apprentissage mélangeant cirque et psychomotricité via une approche ludique et créative.</li>
                    </ul>

                    <ul>
                        <li>Le jeu d'acteur: C'est apprendre à jouer et à découvrir son personnage.</li>
                    </ul>

                    <ul>
                        <li>L'équilibre:sur les mains, le cable, l'acro portée.</li>
                    </ul>

                    <ul>
                        <li>L'entraînement libre: C'est un espace d'entrainement dans lequel on peut travailler librement la technique de son choix.</li>
                    </ul>

                    <ul>
                        <li>Le cours dirigé: Il s'agit d'une recherche créative autour d'une technique spécifique.</li>
                    </ul>

                    <p>&nbsp;</p>

                    <p><strong>Afin de respecter au mieux les attentes et la progression de l'élève, différents niveaux sont proposés pour plusieurs disciplines.</strong></p>

                    <p><span className="underline">Niveau 1:</span> Débutant, acquisition des techniques de base</p>

                    <p><span className="underline">Niveau 2:</span> Avancé, perfectionnement et exploitation de techniques plus poussées.</p>

                    <p>&nbsp;</p>

                    <p className="warning">
                        <strong>Nous vous invitons à prendre connaissance de notre règlement d'ordre intérieur,
                            <a target="_blank" rel="noopener noreferrer" download href="/documents/roi-crea.pdf"> ici :)</a>
                        </strong></p>
                </div>

                {/* {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayDescriptionForm stages={undefined} courses={list} title="Choisir votre cours"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            } */}
            </div>
        </div>
    )
};

export default Description;
