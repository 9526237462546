
import React from 'react';
// import { Link } from 'react-router-dom';
// import DisplayEcolesForm from '../../components/DisplayEcolesForm';
// import axiosInstance from "../../helpers/Axios";

// const defaultList: ICourseObj[] = [];

// interface IDay {
//   name: string;
// }

// interface ICourse {
//   shortName: string;
//   name: string;
//   day: IDay;
//   isVisible: boolean;
//   startTime: string;
//   endTime: string;
// }


// interface IEcoles {
//   EcolesId: number,
//   firstName: string,
//   lastName: string
// }

// interface ICourseObj {
//   course: ICourse;
//   subscribes: IEcoles[];
// }


const Ecoles = () => {

    // const [list, setList] = useState(defaultList);
    // const [visible, setVisible] = useState(true);
    // const loading = list === defaultList && visible;

    // useEffect(() => {
    //     if (loading)
    //         axiosInstance.get("/course")
    //             .then(response => {
    //                 //console.log('response', response);

    //                 setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
    //                 // console.log("réponse",response.data);
    //                 // setUserProfile(response.data);
    //                 // setLocation(response.data.location);
    //             })
    //             .catch((err) => {
    //                 const error = err.response === undefined ?
    //                     'Server in not reachable'
    //                     : err.response.data;
    //                 console.log('error', error);
    //                 setVisible(false);
    //             });
    // }
    // );

    // console.log("list", list);

    return (
        <div id="Ecoles">
            <div id="title">
                <h1>Les Ecoles</h1>
            </div>
            <div id="content">

                <div id="cell-60df284686757676dee40ae4" className="column-content">            							<p>&nbsp;</p>

                    {/* <p>
                        <span className="small">
                            <strong>
                                <a href="/images/courses/horaires.jpg" rel="iframe" title="image horaires" className="fancybox" data-fancybox-type="image">
                                    <img alt="garçon en porté" className="img-center margin-leftright-auto block" height="172" src="/images/courses/horaires.jpg?fx=r_200_200" width="218" />
                                </a>
                            </strong>
                        </span>
                    </p> */}


                    <p>
                        <img alt="Photo pr site" className="img-center" height="426" src="/images/courses/ecoles.jpg" width="640" /></p>

                    <p>&nbsp;</p>

                    <p>Créacirque vous propose d’explorer l’univers magique du cirque par le biais d’activités ludiques &amp; éducatives. Ces ateliers pluridisciplinaires permettront à l’enfant de développer ses compétences motrices, intellectuelles &amp; aussi relationnelles. Il pourra s’initier aux différents agrès de cirque &amp; trouver un moyen d’expression lui correspondant au mieux.</p>

                    <p><br />
                        Plusieurs formules sont possibles...</p>

                    <p><br />
                        •&nbsp;<strong>Une demi-journée:</strong>&nbsp;Il s’agit d’une initiation aux différentes techniques de cirque.<br />
                        •&nbsp;<strong>Une journée:</strong>&nbsp;La matinée sera consacrée à la découverte des divers agrès de cirque<br />
                        &nbsp;&nbsp;&nbsp;&nbsp; &amp; l’après-midi se concentrera sur une technique choisie à développer.<br />
                        •&nbsp;<strong>Plusieurs séances:</strong>&nbsp; Les différentes techniques de cirque seront abordées avec comme objectif la réalisation d’un spectacle .<br />
                        <br />
                        Devis réalisé à la demande.</p>
                </div>
                {/* {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayEcolesForm stages={undefined} courses={list} title="Choisir votre cours"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            } */}
            </div>
        </div>
    )
};

export default Ecoles;
