
import React from 'react';
// import { Link } from 'react-router-dom';
// import DisplayPrixForm from '../../components/DisplayPrixForm';
// import axiosInstance from "../../helpers/Axios";

// const defaultList: ICourseObj[] = [];

// interface IDay {
//   name: string;
// }

// interface ICourse {
//   shortName: string;
//   name: string;
//   day: IDay;
//   isVisible: boolean;
//   startTime: string;
//   endTime: string;
// }


// interface IPrix {
//   PrixId: number,
//   firstName: string,
//   lastName: string
// }

// interface ICourseObj {
//   course: ICourse;
//   subscribes: IPrix[];
// }


const Prix = () => {

    // const [list, setList] = useState(defaultList);
    // const [visible, setVisible] = useState(true);
    // const loading = list === defaultList && visible;

    // useEffect(() => {
    //     if (loading)
    //         axiosInstance.get("/course")
    //             .then(response => {
    //                 //console.log('response', response);

    //                 setList(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
    //                 // console.log("réponse",response.data);
    //                 // setUserProfile(response.data);
    //                 // setLocation(response.data.location);
    //             })
    //             .catch((err) => {
    //                 const error = err.response === undefined ?
    //                     'Server in not reachable'
    //                     : err.response.data;
    //                 console.log('error', error);
    //                 setVisible(false);
    //             });
    // }
    // );

    // console.log("list", list);

    return (
        <div id="Prix">
            <div id="title">
                <h1>Les prix des cours</h1>
            </div>
            <div id="content">

                <div id="cell-57acaa5ace2a000000022f95" className="column-content">            							<p><u>Paiement à l'année pour la saison 2024-2025</u></p>

                    <p>.</p>
                    {/* align="center" border="0" */}
                    <table >
                        <tbody>
                            <tr>
                                <td>&nbsp;</td>
                                <td><strong>sept/juin</strong></td>
                            </tr>
                            <tr>
                                <td><strong>1h</strong></td>
                                <td><strong>235€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>1h30</strong></td>
                                <td><strong>300€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>2h</strong></td>
                                <td><strong>350€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>2h30</strong></td>
                                <td><strong>370€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>3h</strong></td>
                                <td><strong>415€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>3h30</strong></td>
                                <td><strong>420€</strong></td>
                            </tr>
                            <tr>
                                <td><strong>le forfait=4h et +</strong></td>
                                <td><strong>440€</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <p>Réduction pour cours et stage: -10% si 2 inscrits d'une même famille ou famille nombreuse.</p>

                    <p>Si vous rencontrez des difficultés pour le paiement, vous pouvez prendre contact avec nous, nous trouverons un arragement.</p>

                    <p>&nbsp;</p>

                    <p>Pour le cours de préparation physique du mercredi: Possibilité de carte de 10 séances pour 90€ valable toute l'année. On peut venir quand on veut de manière occasionnelle.</p>
                </div>



                {/* {
        loading ?
        <p>Chargement en cours...</p>
        :
        visible ?
          <DisplayPrixForm stages={undefined} courses={list} title="Choisir votre cours"/>
          :
            <div>
              <p>Le système d'inscription est actuellement en panne.</p>
            </div>
            } */}
            </div>
        </div>
    )
};

export default Prix;
