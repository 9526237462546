import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

import Description from './Cours/Description';
import Ecoles from './Cours/Ecoles';
import Horaires from './Cours/Horaires';
import Inscription from './Cours/Inscription';
import Prix from './Cours/Prix';


const Courses = () => {

  const [activeTab, setActiveTab] = useState('inscription');

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <div id="courses">
      <div id="title">
        <h1>Les cours</h1>
      </div>

      <div className="menu-tabs">
        <a href="#description"
          className={activeTab === 'description' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick('description');
          }}>Description</a>
        <a href="#horaires"
          className={activeTab === 'horaires' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick('horaires');
          }}>Les horaires</a>
        <a href="#prix"
          className={activeTab === 'prix' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick('prix');
          }}>Les prix</a>
        <a href="#inscription"
          className={activeTab === 'inscription' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick('inscription');
          }}>Inscription</a>
        <a href="#ecoles"
          className={activeTab === 'ecoles' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick('ecoles');
          }}>Pour les écoles</a>
      </div>
      <div id="content" className="tabs">
        <div id="description" className={activeTab === 'description' ? 'active' : ''}><Description /></div>
        <div id="horaires" className={activeTab === 'horaires' ? 'active' : ''}><Horaires /></div>
        <div id="inscription" className={activeTab === 'inscription' ? 'active' : ''}><Inscription /></div>
        <div id="prix" className={activeTab === 'prix' ? 'active' : ''}><Prix /></div>
        <div id="ecoles" className={activeTab === 'ecoles' ? 'active' : ''}><Ecoles /></div>
      </div>
    </div>
  )
};

export default Courses;
